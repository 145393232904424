import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import {
  checkPhoneNumber,
  customerListing,
  login,
  onGetOtpSignup,
  onOtpSignUpCheck,
  signup,
  signupWithIdp,
} from '../../ducks/auth.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { updateProfileUser } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  getStrapiHeroVidoes,
  getStrapiImages,
  getStrapiSubHeroContent,
  getStrapiSubHeroVideos,
} from '../../util/api';
import { IconSpinner } from '../../components';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    submitSignup,
    onManageDisableScrolling,
    currentUser,
    isAuthenticated,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    onUpdateProfile,
    signupError,
    confirmError,
    signupInProgress,
    signupSuccess,
    loginError,
    onCustomerListing,
    getOtpSignup,
    onOtpSignUpCheck,
    otpData,
    otpInProgress,
    otpCheckData,
    otpCheckError,
    doesPhoneExist,
    onCheckPhoneNumber,
    otpCheckInProgress,
    fetchVendorsInProgress,
    fetchVendors,
    updateInProgress,
    loginInProgress
  } = props;

  const [heroVideosUri, setHeroVideosUri] = useState(false);
  const [subHeroVideosUri, setSubHeroVideosUri] = useState(false);
  const [subHeroContentUri, setSubHeroContentUri] = useState(false);
  const [subImageContent, setSubImageContent] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVideoloading, setVideoLoading] = useState(false);
  const [isVideoSubloading, setVideoSubLoading] = useState(false);

  const [loadingError, setLoadingError] = useState(null);
  useEffect(() => {
    fetchVideos();
    fetchData();
    fetchSubVideos();
  }, []);

  const fetchData = async () => {
    const [subHeroContentResponse, imagesResponse] = await Promise.all([
      getStrapiSubHeroContent({}),
      getStrapiImages({}),
    ]);
    setSubHeroContentUri(subHeroContentResponse?.data?.attributes?.Heading ?? []);
    setSubImageContent(imagesResponse?.data?.attributes ?? []);
    setLoading(false);
  };
  const fetchVideos = async () => {
    try {
      setVideoLoading(true);
      const res = await getStrapiHeroVidoes({});
      setHeroVideosUri(res?.data?.attributes?.Videos?.data ?? []);
      setVideoLoading(false);
    } catch (err) {
      console.log(err, '&&& &&& => err');
      setVideoLoading(false);
    }
  };
  const fetchSubVideos = async () => {
    try {
      setVideoSubLoading(true);
      const res = await getStrapiSubHeroVideos({});
      setSubHeroVideosUri(res?.data?.attributes?.SubVideos?.data ?? []);
      setVideoSubLoading(false);
    } catch (err) {
      console.log(err, '&&& &&& => err');
      setVideoSubLoading(false);
    }
  };
  const totalIndex = subHeroVideosUri.length + subHeroContentUri.length;
  const combinedArray = [];

  for (let i = 0; i < totalIndex; i++) {
    if (i % 2 === 0 && subHeroVideosUri[i / 2]) {
      combinedArray.push(subHeroVideosUri[i / 2]);
    }
    if (i % 2 !== 0 && subHeroContentUri[(i - 1) / 2]) {
      combinedArray.push(subHeroContentUri[(i - 1) / 2]);
    }
  }

  return (
    <>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
        isLandingPage={true}
        options={{
          submitSignup: submitSignup,
          onManageDisableScrolling: onManageDisableScrolling,
          currentUser: currentUser,
          isAuthenticated: isAuthenticated,
          onUpdateProfile: onUpdateProfile,
          tosAssetsData: tosAssetsData,
          tosFetchInProgress: tosFetchInProgress,
          tosFetchError: tosFetchError,
          signupError: signupError,
          confirmError: confirmError,
          signupInProgress: signupInProgress,
          signupSuccess: signupSuccess,
          loginError: loginError,
          onCustomerListing: onCustomerListing,
          getOtpSignup: getOtpSignup,
          onOtpSignUpCheck: onOtpSignUpCheck,
          otpData: otpData,
          otpInProgress: otpInProgress,
          otpCheckData: otpCheckData,
          otpCheckError: otpCheckError,
          doesPhoneExist: doesPhoneExist,
          onCheckPhoneNumber: onCheckPhoneNumber,
          otpCheckInProgress: otpCheckInProgress,
          heroVideosUri: heroVideosUri,
          subHeroVideosUri: subHeroVideosUri,
          combinedArray: combinedArray,
          subImageContent: subImageContent,
          isVideoloading: isVideoloading,
          isVideoSubloading: isVideoSubloading,
          fetchVendorsInProgress: fetchVendorsInProgress,
          fetchVendors: fetchVendors,
          updateInProgress:updateInProgress,
          loginInProgress:loginInProgress
        }}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { currentUser } = state.user;
  const {
    isAuthenticated,
    loginError,
    signupError,
    confirmError,
    signupInProgress,
    signupSuccess,
    otpData,
    otpInProgress,
    otpCheckData,
    otpCheckError,
    doesPhoneExist,
    otpCheckInProgress,
    fetchVendorsInProgress,
    fetchVendors,
    loginInProgress
  } = state.auth;
  const {
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};
  return {
    pageAssetsData,
    inProgress,
    error,
    currentUser,
    isAuthenticated,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    signupError,
    confirmError,
    signupInProgress,
    signupSuccess,
    loginError,
    otpData,
    otpInProgress,
    otpCheckData,
    otpCheckError,
    doesPhoneExist,
    otpCheckInProgress,
    fetchVendorsInProgress,
    fetchVendors,
    updateInProgress,
    updateProfileError,
    loginInProgress
  };
};
const mapDispatchToProps = dispatch => ({
  submitSignup: params => dispatch(signup(params)),
  onCustomerListing: listingParams => dispatch(customerListing(listingParams)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateProfile: data => dispatch(updateProfileUser(data)),
  getOtpSignup: phone => dispatch(onGetOtpSignup(phone)),
  onOtpSignUpCheck: params => dispatch(onOtpSignUpCheck(params)),
  onCheckPhoneNumber: params => dispatch(checkPhoneNumber(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LandingPageComponent);

export default LandingPage;
